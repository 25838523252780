class TenderService {
    constructor(
        $state,
        $filter,
        http,
        $http,
        ntFlash,
        documentsService,
        PROCUREMENTS,
        DATETIME_FROMAT,
    ) {
        this.PROCUREMENTS = PROCUREMENTS;

        let createTenderResource = 'tender/',
            addPlanToTenderResource = 'tender/:tenderId/plans',
            createTenderResourceFromPlan = 'plans/:planId/tenders',
            getTenderResource = 'tender/:tenderId/',
            getTender2StageResource = 'tender/:tenderId/stage2',
            deleteTenderResource = 'tender/:tenderId/cancel/',
            confirmDeleteTenderResource = 'tender/:tenderId/cancel/:cancellationId/',
            editTenderResource = 'tender/:tenderId/',
            cancellationId = null,
            tenderId = null,
            lotId = null,
            config = null,
            tenderData = null;

        let addLocalDraftResource = 'tender/',
            editLocalDraftResource = 'tender/:tenderId/',
            deleteLocalDraftResource = 'tender/:tenderId/';

        let checkEntityResource = 'entity/:entityId';


        const configData = (tender, config) =>{
            const pmt = tender.procurementMethodType

            if(config.hasAuction.value === false && tender.minimalStep){
                delete tender.minimalStep
            }
            if(config.hasAuction.value === false && tender.submissionMethod){
                delete tender.submissionMethod
            }

            if(config.hasValueEstimation.value === false){
                tender.lots.map((lot) =>{
                    const uLot = {...lot}
                    delete lot.value.amount
                    return uLot
                })
                delete tender.value.amount
            }

            const configField = {}
            for (let key in config) {
                configField[key] = config[key].value;
            }

            return {
                data: tender,
                config: configField
            }
        }

        const formatData = (data, isCreateTender, isPublishing = false, config) => {
            if (!data.title) {
                return data;
            }

            var tenderFeatures = data.features ? data.features : [];
            var tenderLots = data.lots || [];
            var tenderItems = data.items;
            var tenderMilestones = data.milestones||undefined;

            if (data.procurementMethodType === 'closeFrameworkAgreementSelectionUA') {
                var output = {
                    title: data.title,
                    title_en: data.title_en,
                    description: data.description,
                    description_en: data.description_en,
                    // tenderPeriod: {
                    //     endDate: data.tenderPeriod.endDate,
                    //     startDate: data.tenderPeriod.startDate,
                    // },
                    lots: [],
                    items: [],

                    procurementMethodType: data.procurementMethodType,
                    agreements: data.agreements
                };
                if (isCreateTender) {
                    delete output.tenderPeriod;
                }
                if (!isCreateTender) {
                    delete output.agreements;
                }

            } else {
                var output = {
                    title: data.title,
                    description: data.description,
                    features: [],
                    lots: [],
                    items: [],
                    milestones: [],
                    tenderPeriod: {
                        endDate: data.tenderPeriod ? data.tenderPeriod.endDate == null ?
                            null :
                            $filter('date')(data.tenderPeriod.endDate, DATETIME_FROMAT) : null,
                    },
                    procurementMethodType: data.procurementMethodType,
                };
                if(data.value && data.value.amount && (data.value.amount > 0 || data.value.amount !== null)){
                    output.value = {
                        amount: data.procurementMethodType !== 'esco' ? data.value.amount : '',
                        valueAddedTaxIncluded: data.procurementMethodType !== 'esco' ? data.value.valueAddedTaxIncluded : '',
                        currency: data.procurementMethodType !== 'esco' ? data.value.currency : ''
                    }
                }
                if(data.minimalStep){
                    output.minimalStep = {
                        amount: data.minimalStep ? data.minimalStep.amount : null,
                        valueAddedTaxIncluded: data.procurementMethodType !== 'esco' ? data.value.valueAddedTaxIncluded : '',
                        currency: data.procurementMethodType !== 'esco' ? data.value.currency : ''
                    }
                }

                if(data.cause && data.cause !== ''){
                    output.cause = data.cause
                }

                if(data.causeDescription && data.causeDescription !== ''  && data.cause !== ''){
                    output.causeDescription = data.causeDescription
                }

            }

            if (data.mainProcurementCategory) {
                output.mainProcurementCategory = data.mainProcurementCategory;
            }

            // if(this.isCompetitiveDialogue2Stage(data)) {
            //     output.status = data.status;
            // }
            tenderFeatures.forEach(function (feature) {
                var ftr = {
                    title: feature.title,
                    title_en: feature.title_en,
                    description: feature.description,
                    description_en: feature.description_en,
                    featureOf: feature.featureOf,
                    relatedItem: feature.relatedItem,
                    enum: []
                };

                feature.enum.forEach(function (option) {
                    ftr.enum.push({
                        title: option.title,
                        description: option.description,
                        value: option.value
                    });
                });
                if (output.features) {
                    output.features.push(ftr);
                } else {
                    output.features = [];
                    output.features.push(ftr);
                }
            });
            if ( tenderMilestones ) {
                output.milestones = tenderMilestones;
            }
            tenderItems.forEach(function (item) {
                let info = {
                    id: item.id.toString(),
                    description: item.description,
                    classification: {
                        scheme: "ДК021",
                        id: item.classification.id,
                        description: item.classification.description
                    },
                    unit: {
                        code: item.unit.code,
                        name: item.unit.name
                    },
                    quantity: item.quantity,
                    deliveryDate: {
                        startDate: item.deliveryDate.startDate == null ? null : $filter('date')(item.deliveryDate.startDate, DATETIME_FROMAT),
                        endDate: item.deliveryDate.endDate == null ? null : $filter('date')(item.deliveryDate.endDate, DATETIME_FROMAT)
                    },
                    deliveryAddress: {
                        countryName: item.deliveryAddress.countryName,
                        postalCode: item.deliveryAddress.postalCode,
                        region: item.deliveryAddress.region,
                        locality: item.deliveryAddress.locality,
                        streetAddress: item.deliveryAddress.streetAddress
                    },
                    relatedLot: item.relatedLot
                };

                if(item.unit.value && item.unit.value.amount){
                    info.unit.value = {
                        amount: item.unit.value.amount,
                        currency: (data.value) ? data.value.currency: item.unit.value.currency,
                        valueAddedTaxIncluded: (data.value) ?
                            data.value.valueAddedTaxIncluded : item.unit.value.valueAddedTaxIncluded
                    }
                }
                if(item.profile && item.profile !== ''){
                    info.profile = item.profile
                    info.category = item.category
                }

                info.additionalClassifications = [];
                (item.additionalClassifications || []).forEach((cl) => {
                    info.additionalClassifications.push({
                        scheme: cl.scheme,
                        id: cl.id,
                        description: cl.description,
                    });
                });

                info.deliveryLocation = item.deliveryLocation;

                // crutch by quinta
                if (data.procurementMethodType === "esco" && !info.deliveryDate.endDate) {
                    delete info.deliveryDate;
                    delete info.unit;
                }

                output.items.push(info);

                if (data.procurementMethodType === "aboveThresholdEU" ||
                    data.procurementMethodType === "closeFrameworkAgreementUA" ||
                    data.procurementMethodType === "competitiveDialogueEU" ||
                    data.procurementMethodType === "competitiveDialogueEU.stage2" ||
                    data.procurementMethodType === "aboveThresholdUA.defense" ||
                    data.procurementMethodType === "simple.defense" ||
                    data.procurementMethodType === "esco"
                ) {
                    output.items[output.items.length - 1].description_en = item.description_en;
                }

            });

            tenderLots.forEach(function (lot) {
                let item
                if(data.procurementMethodType !== 'closeFrameworkAgreementSelectionUA'){
                    item = {
                        id: lot.id.toString(),
                        title: lot.title,
                        title_en: lot.title_en,
                        guarantee: {
                            amount: lot.guarantee && lot.guarantee.amount ? lot.guarantee.amount : 0,
                            currency: lot.guarantee && lot.guarantee.currency ? lot.guarantee.currency : 'UAH'
                        },
                        description: lot.description,
                        description_en: lot.description_en,
                        value: {
                            currency: "UAH",
                            amount: data.procurementMethodType !== 'esco' ? lot.value.amount : '',
                            valueAddedTaxIncluded: data.procurementMethodType !== 'esco' ? data.value.valueAddedTaxIncluded : ''
                        }
                    };
                } else {
                    item = {
                        title: lot.title,
                        title_en: lot.title_en,
                        id: lot.id,
                        description: lot.description
                        }
                        if(lot.guarantee){
                            item.guarantee = {
                                amount: lot.guarantee && lot.guarantee.amount ? lot.guarantee.amount : 0,
                                currency: lot.guarantee && lot.guarantee.currency ? lot.guarantee.currency : 'UAH'
                            }
                        }
                }


                if (lot.minimalStep && data.procurementMethodType !== 'esco') {
                    item.minimalStep = {
                        currency: "UAH",
                        amount: lot.minimalStep.amount,
                        valueAddedTaxIncluded: data.value.valueAddedTaxIncluded
                    };
                }
                if (data.procurementMethodType === "esco.negotiation") {
                    item.minimalStepPercentage = lot.minimalStepPercentage / 100;
                    // item.fundingKind = lot.fundingKind;
                    item.yearlyPaymentsPercentageRange = lot.yearlyPaymentsPercentageRange / 100;
                }
                if (data.procurementMethodType === 'esco') {
                    item.minimalStepPercentage = lot.minimalStepPercentage / 100;
                    // item.fundingKind = lot.fundingKind;
                    item.yearlyPaymentsPercentageRange = lot.yearlyPaymentsPercentageRange / 100;
                }
                output.lots.push(item);
            });

            if (data.procurementMethodType === "belowThreshold") {

                if (data.funders) {
                    data.funders.forEach(function (funder) {
                        funder.identifier.uri === "" ? delete(funder.identifier.uri) : "";
                    });
                    output.funders = data.funders;
                }

                if (data.inspector) {
                    output.inspector = data.inspector;
                }

                output.enquiryPeriod = {
                    endDate: data.enquiryPeriod.endDate == null ?
                        null : $filter('date')(data.enquiryPeriod.endDate, DATETIME_FROMAT),
                    startDate: data.enquiryPeriod.startDate == null ?
                        null : $filter('date')(data.enquiryPeriod.startDate, DATETIME_FROMAT)
                };
                output.tenderPeriod.startDate = data.tenderPeriod.startDate == null ?
                    null :
                    $filter('date')(data.tenderPeriod.startDate, DATETIME_FROMAT);
            }

            if (data.procurementMethodType === "aboveThresholdEU" ||
                data.procurementMethodType === "closeFrameworkAgreementUA" ||
                data.procurementMethodType === "competitiveDialogueEU" ||
                data.procurementMethodType === "competitiveDialogueEU.stage2" ||
                data.procurementMethodType === "aboveThresholdUA.defense" ||
                data.procurementMethodType === "simple.defense" ||
                data.procurementMethodType === "esco"
            ) {
                output.title_en = data.title_en;
                output.description_en = data.description_en;
            } else {
                if (output.lots && output.lots.length) {
                    output.lots.forEach(function (lot) {
                        delete lot.title_en;
                        delete lot.description_en;
                    });
                }
                if (output.features && output.features.length) {
                    output.features.forEach(function (feature) {
                        delete feature.title_en;
                        delete feature.description_en;
                    });
                }
            }

            if (data.procurementMethodType === "belowThreshold" ||
                data.procurementMethodType === "closeFrameworkAgreementUA" ||
                data.procurementMethodType === "aboveThresholdEU" ||
                data.procurementMethodType === "aboveThreshold" ||
                data.procurementMethodType === "competitiveOrdering" ||
                data.procurementMethodType === "aboveThresholdUA" ||
                data.procurementMethodType === "competitiveDialogueUA" ||
                data.procurementMethodType === "competitiveDialogueUA.stage2" ||
                data.procurementMethodType === "competitiveDialogueEU" ||
                data.procurementMethodType === "competitiveDialogueEU.stage2" ||
                data.procurementMethodType === "aboveThresholdUA.defense" ||
                data.procurementMethodType === "simple.defense" ||
                data.procurementMethodType === "closeFrameworkAgreementSelectionUA" ||
                data.procurementMethodType === "esco"
            ) {

                if (data.guarantee) {
                    output.guarantee = {
                        amount: data.guarantee.amount ? data.guarantee.amount : 0,
                        currency: data.guarantee.currency ? data.guarantee.currency : 'UAH'
                    };
                }
            } else {
                output.lots.forEach(function (lot) {
                    delete lot.guarantee;
                });
            }

            if (data.procurementMethodType === "reporting" ||
                data.procurementMethodType === "negotiation" ||
                data.procurementMethodType === "negotiation.quick") {

                delete output.tenderPeriod;
                delete output.minimalStep;
                delete output.features;
                if (data.procurementMethodType === "reporting") {
                    delete output.lots;
                }
            }

            if (data.procurementMethodType === "competitiveOrdering" && isCreateTender) {

                output.agreements = data.agreements
            }
            if(data.procurementMethodType === "reporting" && data.procuringEntity.kind === 'defense' && data.value && (data.value.amount === 0 || data.value.amount === null)){
                delete output.value
            }

            if (data.procurementMethodType === "negotiation" ||
                data.procurementMethodType === "negotiation.quick") {

                output.cause = data.cause;
                output.causeDescription = data.causeDescription;

                if (output.lots && output.lots.length) {
                    output.lots.forEach(function (lot) {
                        delete lot.minimalStep;
                    });
                }
            }

            if (data.procurementMethodType === "esco" || data.procurementMethodType === "esco.negotiation") {

                output.NBUdiscountRate = data.NBUdiscountRate / 100;
                // output.fundingKind = data.fundingKind;
                data.fundingKind === 'other' ? output.yearlyPaymentsPercentageRange = 0.8 : output.yearlyPaymentsPercentageRange = 0;
                output.minimalStepPercentage = 0.03; // max possible value
                delete output.minimalStep;
                delete output.value;
                delete output.unit;
                if (output.lots && output.lots.length) {
                    output.lots.forEach((lot) => {
                        if (data.fundingKind === 'other') lot.yearlyPaymentsPercentageRange = 0.8;
                        if (data.fundingKind === 'budget') {
                            lot.fundingKind = 'budget';
                            if (lot.yearlyPaymentsPercentageRange > output.yearlyPaymentsPercentageRange) {
                                output.yearlyPaymentsPercentageRange = lot.yearlyPaymentsPercentageRange;
                            }
                        }
                        if (lot.minimalStepPercentage && lot.minimalStepPercentage < output.minimalStepPercentage) {
                            output.minimalStepPercentage = lot.minimalStepPercentage;
                        }
                        delete lot.minimalStep;
                        delete lot.value;
                    });
                }

            }

            if (data.procurementMethodType === "closeFrameworkAgreementUA") {
                output.maxAwardsCount = data.maxAwardsCount;
                output.agreementDuration = data.agreementDuration;
            }

            output.procuringEntity = data.procuringEntity; // replaced here becouse this field became required

            if (!["competitiveDialogueEU",
                 "competitiveDialogueEU.stage2",
                 "aboveThresholdEU"
                ].includes(data.procurementMethodType)) delete output.procuringEntity.contactPoint.availableLanguage;

            if (data.buyers) output.buyers = data.buyers;
            if(isCreateTender){
                if (data.plans) output.plans = data.plans;

            }
            if (data.procurementMethodRationale !== undefined) output.procurementMethodRationale = data.procurementMethodRationale;
            if (data.cdb_id) output.cdb_id = data.cdb_id;
            if (data.criteria) {
                output.criteria = JSON.parse(angular.toJson(data.criteria, false));
                output.criteria.forEach(item => {
                    delete item.activate;
                    if (item.relatesTo !== 'lot') delete item.relatedItem;
                });
            }
            if (data.awardCriteria) output.awardCriteria = data.awardCriteria;
            if (data.awardCriteria === 'lifeCycleCost') output.features = [];

            if(!['belowThreshold'].includes(data.procurementMethodType)){
                if(output.tenderPeriod ) {
                    if (output.tenderPeriod.endDate &&
                        (output.tenderPeriod.endDate === $filter('date')(data.tempTenderPeriod.endDate, DATETIME_FROMAT))) {
                        delete output.tenderPeriod;
                    } else {
                        output.tenderPeriod.startDate = data.tempTenderPeriod.startDate
                    }
                }
            }
            if(isPublishing && !['belowThreshold', 'reporting', 'negotiation', 'negotiation.quick'].includes(data.procurementMethodType)){
                output.tenderPeriod = {
                    // startDate : $filter('date')(data.tenderPeriod.startDate, DATETIME_FROMAT),
                    endDate : $filter('date')(data.tenderPeriod.endDate, DATETIME_FROMAT)
                }
                if(! isCreateTender){
                    output.tenderPeriod.startDate = $filter('date')(data.tenderPeriod.startDate, DATETIME_FROMAT)
                }
            }

            if(config && config.hasAuction === false){
                delete output.minimalStep
            }

            if(config && config.hasAuction === false){
                delete output.minimalStep
            }



            return output;
        };

        const confirmDelete = (data) => {
            var confirmDeleteUrl = confirmDeleteTenderResource.replace(/:tenderId/, data.tenderId).replace(/:cancellationId/, data.cancellationId);
            return http.put(confirmDeleteUrl, {});
        };



        const sendRequests = (files, count, callback) => {
            var currentCount = count ? count : 0;
            if (currentCount <= (files.length - 1)) {
                documentsService.save(files[currentCount], tenderId, {
                        cancellationId: cancellationId
                    })
                    .then(function () {
                        currentCount += 1;
                        return sendRequests(files, currentCount, callback);
                    });
            } else {
                confirmDelete({
                        tenderId: tenderId,
                        cancellationId: cancellationId
                    })
                    .then(function () {
                        callback();
                    });
            }

        };
        class Tender {
            constructor(procurementMethodType, isMultilots) {
                this.procurementMethodType = procurementMethodType || "";

                if (isMultilots) {
                    this.lots = [];
                }
                this.items = [];
                this.milestones = [];
                this.title = null;
                this.title_en = null;
                this.description = null;
                this.description_en = null;

                this.minimalStep = {
                    amount: ""
                };

                this.guarantee = {
                    amount: "",
                    currency: "UAH"
                };

                this.tenderPeriod = {
                    startDate: null,
                    startDateIsOpen: false,
                    endDate: null,
                    endDateIsOpen: false
                };

                this.enquiryPeriod = {
                    endDate: null,
                    endDateIsOpen: false,
                    startDate: null,
                    startDateIsOpen: false
                };

                this.value = {
                    amount: "",
                    currency: "UAH",
                    valueAddedTaxIncluded: false
                };

                this.features = [];

            }
        }
        this.newTender = (procurementMethodType, isMultilots) => {
            tenderData = new Tender(procurementMethodType, isMultilots);
            return tenderData;
        };
        this.setTender = (tender) => {
            tenderData = tender;
            return tenderData;
        };
        this.setDefaultConfig = (tender, isCreateMode) => {
            config = {
                hasAuction : {
                    value: true,
                    canEdit: true
                },
                hasAwardingOrder: {
                    value: true,
                    canEdit: false
                },
                hasPrequalification : {
                    value: ['aboveThresholdEU', 'competitiveDialogueUA', 'competitiveDialogueEU', 'competitiveDialogueEU.stage2', 'esco', 'closeFrameworkAgreementUA'].includes(tender.procurementMethodType),
                    canEdit: false
                },
                hasValueRestriction : {
                    value: true,
                    canEdit: false
                },
                valueCurrencyEquality : {
                    value: true,
                    canEdit: false
                },
                minBidsNumber : {
                    value: 1,
                    canEdit: false
                },
                hasPreSelectionAgreement : {
                    value: false,
                    canEdit: false
                },
                hasAwardComplaints : {
                    value: !['competitiveOrdering', 'belowThreshold', 'competitiveDialogueUA', 'competitiveDialogueEU', 'reporting', 'closeFrameworkAgreementSelectionUA', 'priceQuоtation'].includes(tender.procurementMethodType),
                    canEdit: false
                },
                hasCancellationComplaints : {
                    value: !['competitiveOrdering', 'belowThreshold', 'reporting', 'closeFrameworkAgreementSelectionUA', 'priceQuоtation'].includes(tender.procurementMethodType),
                    canEdit: false
                },
                hasTenderComplaints: {
                    value: !['competitiveOrdering', 'belowThreshold', 'negotiation', 'negotiation.quick', 'reporting', 'closeFrameworkAgreementSelectionUA', 'priceQuоtation'].includes(tender.procurementMethodType),
                    canEdit: false
                },
                restricted: {
                    value: false,
                    canEdit: false
                },
                cancellationComplainDuration: {
                    value: 10,
                    canEdit: false
                },
                qualificationDuration: {
                    value: 0,
                    canEdit: false
                },
                qualificationComplainDuration: {
                    value: 0,
                    canEdit: false
                },
                hasValueEstimation: {
                    value: true,
                    canEdit: false
                },
                clarificationUntilDuration: {
                    value: 3,
                    canEdit: false
                },
                tenderComplainRegulation: {
                    value: 4,
                    canEdit: false
                },
                hasQualificationComplaints: {
                    value: false,
                    canEdit: false
                },
                awardComplainDuration: {
                    value: 10,
                    canEdit: false
                }
            }

            if (['aboveThresholdUA', 'aboveThresholdEU', 'competitiveDialogueUA.stage2', 'competitiveDialogueEU.stage2', 'esco'].includes(tender.procurementMethodType)){
                config.minBidsNumber = {
                    value: 2,
                    canEdit: false
                }
            }

            if (['aboveThresholdEU', 'competitiveDialogueUA', 'competitiveDialogueEU', 'competitiveDialogueEU.stage2', 'esco', 'closeFrameworkAgreementUA'].includes(tender.procurementMethodType)){
                config.qualificationComplainDuration = {
                    value: 5,
                    canEdit: false
                }
            }

            if (['competitiveOrdering', 'belowThreshold', 'reporting', 'closeFrameworkAgreementSelectionUA'].includes(tender.procurementMethodType)){
                config.cancellationComplainDuration = {
                    value: 0,
                    canEdit: false
                }
            }

            if (['belowThreshold', 'aboveThresholdEU', 'competitiveDialogueUA', 'competitiveDialogueEU', 'competitiveDialogueEU.stage2', 'esco', 'closeFrameworkAgreementUA'].includes(tender.procurementMethodType)){
                config.qualificationDuration = {
                    value: 20,
                    canEdit: false
                }
            }

            if (['belowThreshold'].includes(tender.procurementMethodType)){
                config.hasValueEstimation = {
                    value: true,
                    canEdit: true
                }
                config.clarificationUntilDuration = {
                    value: 1,
                    canEdit: false
                }
            }

            if (['esco'].includes(tender.procurementMethodType)){
                config.hasValueEstimation = {
                    value: false,
                    canEdit: false
                }
            }

            if (['negotiation', 'negotiation.quick', 'reporting', 'closeFrameworkAgreementSelectionUA'].includes(tender.procurementMethodType)){
                config.clarificationUntilDuration = {
                    value: 0,
                    canEdit: false
                }
            }

            if (['competitiveOrdering', 'belowThreshold', 'negotiation', 'negotiation.quick', 'reporting', 'negotiation', 'closeFrameworkAgreementSelectionUA'].includes(tender.procurementMethodType)){
                config.tenderComplainRegulation = {
                    value: 0,
                    canEdit: false
                }
            }

            if (['aboveThreshold'].includes(tender.procurementMethodType)){
                config.tenderComplainRegulation = {
                    value: 3,
                    canEdit: false
                }
            }

            if (['simple.defense', 'aboveThresholdUA.defense'].includes(tender.procurementMethodType)){
                config.tenderComplainRegulation = {
                    value: 2,
                    canEdit: false
                }
            }

            if (['aboveThresholdEU', 'competitiveDialogueUA', 'competitiveDialogueEU', 'competitiveDialogueEU.stage2', 'esco', 'closeFrameworkAgreementUA'].includes(tender.procurementMethodType)){
                config.tenderComplainRegulation = {
                    value: 2,
                    canEdit: false
                }
            }

            if (['competitiveOrdering', 'aboveThreshold', 'negotiation.quick'].includes(tender.procurementMethodType)){
                config.awardComplainDuration = {
                    value: 5,
                    canEdit: false
                }
            }

            if (['belowThreshold'].includes(tender.procurementMethodType)){
                config.awardComplainDuration = {
                    value: 2,
                    canEdit: false
                }
            }

            if (['simple.defense', 'aboveThresholdUA.defense'].includes(tender.procurementMethodType)){
                config.awardComplainDuration = {
                    value: 4,
                    canEdit: false
                }
            }

            if (['competitiveDialogueUA', 'competitiveDialogueEU', 'reporting', 'closeFrameworkAgreementSelectionUA'].includes(tender.procurementMethodType)){
                config.awardComplainDuration = {
                    value: 0,
                    canEdit: false
                }
            }

            if (['competitiveOrdering'].includes(tender.procurementMethodType)){
                config.restricted = {
                    value: false,
                    canEdit: false
                }
                config.hasPreSelectionAgreement = {
                    value: true,
                    canEdit: false
                }
                config.hasAwardingOrder = {
                    value: true,
                    canEdit: true
                }
                config.valueCurrencyEquality = {
                    value: true,
                    canEdit: true
                }

            }
            if (['competitiveDialogueUA', 'competitiveDialogueEU', 'closeFrameworkAgreementUA'].includes(tender.procurementMethodType)){
                config.minBidsNumber = {
                    value: 3,
                    canEdit: false
                }
            }

            if (['negotiation', 'negotiation.quick', 'competitiveDialogueUA', 'competitiveDialogueEU', 'reporting', 'priceQuоtation'].includes(tender.procurementMethodType)){
                config.hasAuction = {
                    value: false,
                    canEdit: false
                }
            } else {
                config.hasAuction = {
                    value: true,
                    canEdit: ['competitiveOrdering', "belowThreshold","aboveThresholdUA","aboveThreshold","aboveThresholdEU","simple.defense"].includes(tender.procurementMethodType)
                }
            }
            if (tender.procurementMethodType === 'belowThreshold'){
                config.hasAwardingOrder = {
                    value: true,
                    canEdit: true
                }
                config.valueCurrencyEquality = {
                    value: true,
                    canEdit: true
                }
                config.hasPrequalification = {
                    value: false,
                    canEdit: true
                }
                config.minBidsNumber = {
                    value: 1,
                    canEdit: true
                }
            }

            if (['belowThreshold', 'competitiveOrdering'].includes(tender.procurementMethodType)){
                config.hasValueRestriction = {
                    value: true,
                    canEdit: true
                }
            } else if (['aboveThreshold'].includes(tender.procurementMethodType)){
                config.hasValueRestriction = {
                    value: false,
                    canEdit: false
                }
            }

            return config

        }

        this.setEditConfig = (tender) => {

        }

        this.copyTender = (tender) => {
            delete tender.id;
            tender.items.map(item => item.deliveryDate = {});
            // if (tender.procurementMethodType === 'esco') {
            //     tender.yearlyPaymentsPercentageRange = tender.yearlyPaymentsPercentageRange * 100;
            //     tender.lots.map(lot => lot.yearlyPaymentsPercentageRange = lot.yearlyPaymentsPercentageRange*100);
            // }
            tender.enquiryPeriod = {
                startDate: new Date()
            };

            // if (!tender.lots) {
            //     tender.lots = {
            //         field: "",
            //         data: []
            //     };
            // }

            tender.tenderPeriod = {};
            this.setTender(tender);
            return this.currentTender();
        };
        this.currentTender = () => {
            if (tenderData !== null) {
                return tenderData;
            } else {
                return new Tender;
            }
        };
        this.get = (id, _url) => {
            let tenderUrl = _url ? _url.replace(/\/opc\//, '') : getTenderResource.replace(/:tenderId/, id);
            return http.get(tenderUrl)
                .then((result) => {
                    //console.log("tenderService.get.data.response:");
                    //console.log(result.data.response);
                    return result.data.response;
                }, (error) => {
                    //ntFlash.error(gettext('Тендера с указанным идентификатором не существует'));
                    $state.go('tendersList.type');
                });
        };
        this.delete = (tenderId, lotId, reason, reasonType) => {
            let deleteUrl = deleteTenderResource.replace(/:tenderId/, tenderId);
            let data = {};
            data.reason = reason;

            if (reasonType && reasonType !== "") {
                data.reasonType = reasonType;
            }
            if (lotId) {
                data.cancellationOf = "lot";
                data.relatedLot = lotId;
            }

            return http.post(deleteUrl, data);
        };
        this.save = (data, isFromPlan) => {
            let save_url;
            if (isFromPlan) {
                save_url = createTenderResourceFromPlan.replace(/:planId/, data.plans[0].id);
            } else {
                save_url = createTenderResource;
            }
            let tender = formatData(data, true);
            return http.post(save_url, tender);
        };
        this.publish = (data) => {
           let request = {
               status: 'publish'
            }
            if(data.procurementMethodType === 'closeFrameworkAgreementSelectionUA'){
                request = {
                    status: 'draft.pending'
                }
            }

            // let editUrl = editTenderResource.replace(/:tenderId/, data);
            return this.patch(data, request);
        };

        this.sendInspectorRequest  = (tenderId) =>{

            return http.post(`/tender/${tenderId}/review_requests/`, {data : {}});
        }

        this.edit = (tenderId, data, config) => {
            let editUrl = editTenderResource.replace(/:tenderId/, tenderId);
            let tender = formatData(data,false, false, config);

            if (tender.procurementMethodType === "competitiveDialogueUA.stage2" ||
                tender.procurementMethodType === "competitiveDialogueEU.stage2") {
                let tenderData = {
                    tenderPeriod: tender.tenderPeriod,
                    items: tender.items
                };
                return this.patch(tenderId, tenderData);
            } else {
                return http.put(editUrl, tender);
            }
        };
        this.patch = (tenderId, data) => {
            let editUrl = editTenderResource.replace(/:tenderId/, tenderId);
            return http.patch(editUrl, data);
        };
        this.confirmDelete = confirmDelete;
        this.totalDelete = (id, lot_id, reason, reasonType, documents, callback) => {
            tenderId = id;
            lotId = lot_id;
            return this.delete(tenderId, lotId, reason, reasonType)
                .then((result) => {
                    cancellationId = result.data.response.id;
                    sendRequests(documents, 0, callback);
                });
        };
        this.updateTenderStatus = (tenderId, status) => {
            let editUrl = editTenderResource.replace(/:tenderId/, tenderId);
            return http.patch(editUrl, {
                status: status
            });
        };

        this.getById = (id) => {
            let tenderUrl = getTenderResource.replace(/:tenderId/, id);
            return http.get(tenderUrl);
        };

        this.getTender2Stage = (id) => {
            let tenderUrl = getTender2StageResource.replace(/:tenderId/, id);
            return http.get(tenderUrl);
        };

        this.addPlanToTenderCZO = (id, planId) => {
            let tenderUrl = addPlanToTenderResource.replace(/:tenderId/, id);
            return http.post(tenderUrl, {
                id: planId
            });
        };

        this.createDraft = (tender, isNewTender, plan = null, config) => {


            //TODO fix tender without plan
            //TODO Check czo
            let url = (plan) ? createTenderResourceFromPlan.replace(/:planId/, tender.plans[0].id) :
                addLocalDraftResource;

            // console.log(url)
            // console.log(url)
            // console.log(formatData(tender, false, config))

            const tenderBody = configData(
                formatData(tender, true, config),
                config
        )
            return http.post(url, tenderBody);
        };

        this.saveLocalDraft = (tender, isNewTender, plan = null, isPublishing = false, config) => {


            //TODO fix tender without plan
            //TODO Check czo
            let url = isNewTender ?
                (plan) ? createTenderResourceFromPlan.replace(/:planId/, tender.plans[0].id) :
                    addLocalDraftResource :
                editLocalDraftResource.replace(/:tenderId/, tender.id);

            // console.log(url)
            // console.log(url)
            // console.log(formatData(tender, false, isPublishing, config))


            return isNewTender ? http.post(url, formatData(tender, true)) : http.put(url, formatData(tender, false, isPublishing, config ));
        };

        this.deleteLocalDraft = (tenderId) => {
            let url = deleteLocalDraftResource.replace(/:tenderId/, tenderId);
            return http.delete(url)
                .then(function(data) {
                    return data;
                });
        }

        this.activateTenderCancellation = (data) => {
            var url = confirmDeleteTenderResource.replace(/:tenderId/, data.tenderId).replace(/:cancellationId/, data.cancellationId);
            return http.put(url, {});
        }

        this.recoverTenderCancellation = (data) => {
            var url = confirmDeleteTenderResource.replace(/:tenderId/, data.tenderId).replace(/:cancellationId/, data.cancellationId);
            return http.put(url, {status: 'unsuccessful'});
        }

        this.checkEntity = (data) => {
            var url = checkEntityResource.replace(/:entityId/, data.entityId);
            return http.get(url)
                .then(function(result) {
                    return result.data;
                });
        }
    }

    isProcurementMethodType(procurement, types) {
        return typeof types === 'string' ?
            types === procurement.procurementMethodType :
            types.indexOf(procurement.procurementMethodType) > -1;
    }

    isCompetitiveDialogue(procurement) {
        let types = ['competitiveDialogueEU', 'competitiveDialogueUA'];
        return this.isProcurementMethodType(procurement, types);
    }

    isMedication(procurement) {
        if (procurement.items && procurement.items[0].classification) {
            return procurement.items[0].classification.id.slice(0, 3) === '336';
        }
    }

    isEsco(procurement) {
        let types = ['esco'];
        return this.isProcurementMethodType(procurement, types);
    }

    isCompetitiveDialogue2Stage(procurement) {
        let types = ['competitiveDialogueEU.stage2', 'competitiveDialogueUA.stage2'];
        return this.isProcurementMethodType(procurement, types);
    }

    isLimitedByMethodType(methodType) {
        return ['negotiation', 'negotiation.quick', 'reporting'].includes(methodType);
    }

    isAbove(procurement) {
        const types = [
            this.PROCUREMENTS.ABOVE_THRESHOLD,
            this.PROCUREMENTS.ABOVE_THRESHOLD_UA,
            this.PROCUREMENTS.ABOVE_THRESHOLD_UA_DEFENSE,
            this.PROCUREMENTS.SIMPLE_DEFENSE,
            this.PROCUREMENTS.ABOVE_THRESHOLD_EU,
            this.PROCUREMENTS.ESCO,
        ];

        return this.isProcurementMethodType(procurement, types);
    }

    isBelow(procurement) {
        const types = [
            this.PROCUREMENTS.BELOW_THRESHOLD
        ];

        return this.isProcurementMethodType(procurement, types);
    }

    isBlockedTender(tender) {
        // if (['unsuccessful', 'complete', 'cancelled'].indexOf(tender.status) > -1) {
        //     return false;
        // }

        const curDate = new Date();
        const daysLen = 1000 * 3600 * 24;

        const checkComplaint = c => (
            c.status !== 'claim' ||
            (
                (!c.resolution || !c.resolution.length) &&
                Math.ceil((curDate - new Date(c.dateSubmitted)) / (daysLen)) > 3)
        );

        const qualifications = tender.qualifications || [];
        const awards = tender.awards || [];
        const cancellations = tender.cancellations || [];

        if (tender.status === 'active.tendering') {
            return this.getAllCompleints([tender, ...cancellations])
                .filter((c) => (
                    ['pending', 'accepted', 'satisfied', 'claim'].indexOf(c.status) > -1 && checkComplaint(c)
                )).length > 0;

        } else if (tender.status === 'active.pre-qualification.stand-still') {
            return this.getAllCompleints([...qualifications, ...cancellations])
                .filter(c => (
                    ['pending', 'accepted', 'satisfied'].indexOf(c.status) > -1
                )).length > 0;

        } else if (
            (tender.procurementMethodType !== 'closeFrameworkAgreementUA' && tender.status === 'active.awarded') ||
            (tender.procurementMethodType === 'closeFrameworkAgreementUA' && tender.status === 'active.qualification.stand-still')
        ) {
            return this.getAllCompleints([...awards, ...cancellations])
                .filter(c => (
                    ['pending', 'accepted', 'satisfied'].indexOf(c.status) > -1
                )).length > 0;

        }
    }

    hasUnansweredComplains(tender) {
        // if (['unsuccessful', 'complete', 'cancelled'].indexOf(tender.status) > -1) {
        //     return false;
        // }

        const checkComplaint = c => (!c.resolution || !c.resolution.length);

        const qualifications = tender.qualifications || [];
        const awards = tender.awards || [];
        const cancellations = tender.cancellations || [];

        if (tender.status === 'active.tendering') {
            return this.getAllCompleints([tender, ...cancellations])
                .filter((c) => (
                    c.status === 'claim' && checkComplaint(c)
                )).length > 0;

        } else if (['active.pre-qualification', 'active.pre-qualification.stand-still'].indexOf(tender.status) > -1) {
            return this.getAllCompleints([...qualifications, ...cancellations]).filter(c => (
                c.status === 'claim' && checkComplaint(c)
            )).length > 0;

        } else if (
            (tender.procurementMethodType !== 'closeFrameworkAgreementUA' && ['active.qualification', 'active.awarded'].indexOf(tender.status) > -1) ||
            (tender.procurementMethodType === 'closeFrameworkAgreementUA' && ['active.qualification', 'active.qualification.stand-still'].indexOf(tender.status) > -1)
        ) {
            return this.getAllCompleints([...awards, ...cancellations]).filter(c => (
                c.status === 'claim' && checkComplaint(c)
            )).length > 0;
        }
    }

    getAllCompleints(items) {
        items = items || [];
        let complaints = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].complaints) {
                complaints = complaints.concat(items[i].complaints);
            }
        }

        return complaints;
    }

    setDocumentTypeAndConfidentialityScheme(tenderStatus, procurementMethodType, isMilestones24h, isMilestonesAlp) {
        let scheme = this.setDocumentTypeAndConfidentialityAll(true);
        switch (procurementMethodType) {
            case 'aboveThresholdUA':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        break;
                }
                break;
            case 'aboveThreshold':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        scheme.buyerOnly = false;
                        break;
                }
                break;
                // TODO Fix belowThreshold docs
            case 'belowThreshold':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        scheme.buyerOnly = false;
                        break;
                }
                break;
            case 'competitiveOrdering':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        scheme.buyerOnly = false;
                        break;
                }
                break;
            case 'aboveThresholdEU':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = false;
                        break;  
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        break;
                }
                break;
            case 'aboveThresholdUA.defense':
            case 'simple.defense':
                scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                if (tenderStatus === 'active.tendering') scheme.buyerOnly = false;
                break;
            case 'esco':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = false;
                        break;  
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        break;
                }
                break;
            case 'competitiveDialogueUA':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        break;
                }
                break;
            case 'competitiveDialogueUA.stage2':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        break;
                }
                break;
            case 'competitiveDialogueEU':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        break;
                }
                break;
            case 'competitiveDialogueEU.stage2':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = scheme.buyerOnly = false;
                        break;  
                    case 'active.qualification':
                        scheme.eligibilityDocuments = false;
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.awarded':
                        scheme.eligibilityDocuments = false;
                        break;
                }
                break;
            case 'closeFrameworkAgreementUA':
                switch (tenderStatus) {
                    case 'active.tendering':
                        scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = scheme.billOfQuantity = false;
                        scheme.buyerOnly = false;
                        break;
                    case 'active.pre-qualification':
                        if (isMilestones24h) scheme.qualificationDocuments = false;
                        break;  
                    case 'active.qualification':
                        if (isMilestonesAlp) scheme.evidence = false;
                        break;
                    case 'active.qualification.stand-still':
                        scheme.eligibilityDocuments = false;
                        break;
                    case 'active.awarded':
                        scheme.winningBid = false;
                        break;
                }
                break;
            default: scheme.technicalSpecifications = scheme.qualificationDocuments = scheme.eligibilityDocuments = scheme.commercialProposal = false;
        }
        return scheme;
    }

    setDocumentTypeAndConfidentialityAll(bool) {
        return {
            technicalSpecifications: bool,
            qualificationDocuments: bool,
            eligibilityDocuments: bool,
            commercialProposal: bool,
            billOfQuantity: bool,
            evidence: bool,
            winningBid: bool,
            buyerOnly: bool
        };
    }

    setMethodRationale(tender) {
        const MethodRationale = {
            methodRationale: [],
            methodRationaleCanEdit: true,
            currentMethodRationale: ''
        }
        if(tender.procurementMethodType === 'reporting') {
            if(tender.procuringEntity.kind === 'defense'){
                const item = {
                    value: 'defense',
                    title: 'Оборонна закупівля'
                }
                MethodRationale.currentMethodRationale = 'defense'
                MethodRationale.methodRationale.push(item)
                MethodRationale.methodRationaleCanEdit = false

            } else {
                const items = [
                    {
                        value: 'COVID-19',
                        title: 'Закупівля для запобігання пандемії COVID-19'
                    },
                    {
                        value: 'war_for_freedom',
                        title: 'Українська Визвольна Війна'
                    }
                ]
                MethodRationale.methodRationale.push(...items)
            }
        }
        if([
            'aboveThreshold',
            'aboveThresholdUA',
            'aboveThresholdEU',
            'closeFrameworkAgreementUA',
            'esco',
            'competitiveDialogueUA',
            'competitiveDialogueEU',
            'simple.defense'
        ].includes(tender.procurementMethodType)){
            const item = {
                value: 'eco_criteria',
                title: 'Екологічні вимоги'
            }
            MethodRationale.methodRationale.push(item)
        }
        if(tender.procurementMethodRationale){
            MethodRationale.currentMethodRationale = tender.procurementMethodRationale
        }
        return MethodRationale;
    }
}
commons.service('tenderService', TenderService);
