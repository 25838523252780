commons.constant('RATIONALE_TYPES', [

    {
        checked: false,
        title: "durationExtension",
        description_en: "extension of the period of the procurement contract duration and the term of obligations fulfilment regarding the transfer of goods, implementation of works and provision of services in case of documented objective circumstances that led to such extension, including circumstances of force majeure, delays in funding of procuring entitys expenditures, provided that such changes will not increase the sum of money specified in the procurement contract",
        title_en: "Extension of the period of the contract duration (due to documented objective circumstances)",
        description_uk: "продовження строку дії договору про закупівлю та строку виконання зобов’язань щодо передачі товару, виконання робіт, надання послуг у разі виникнення документально підтверджених об’єктивних обставин, що спричинили таке продовження, у тому числі обставин непереборної сили, затримки фінансування витрат замовника, за умови що такі зміни не призведуть до збільшення суми, визначеної в договорі про закупівлю",
        title_uk: "Продовження строку дії договору (через документально підтверджені об’єктивні обставини)",
        archive: false,
        changes: "Ціна договору, Ціна договору без ПДВ, Ціна за одиницю",
        info: false
    },
    {
        checked: false,
        title: "fiscalYearExtension",
        description_en: "effect of the procurement contract may be extended for a period sufficient for conduction of the procurement procedure/simplified procurement at the beginning of the next year in volume that does not exceed 20% of the sum specified in the initial procurement contract concluded in the previous year, if expenditures for achieving this goal are approved in the prescribed manner",
        title_en: "Extension of the period of the contract duration to the next year",
        description_uk: "дія договору про закупівлю може бути продовжена на строк, достатній для проведення процедури закупівлі/спрощеної закупівлі на початку наступного року в обсязі, що не перевищує 20 відсотків суми, визначеної в початковому договорі про закупівлю, укладеному в попередньому році, якщо видатки на досягнення цієї цілі затверджено в установленому порядку",
        title_uk: "Продовження строку дії договору на наступний рік",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ, Інф про номенкл, кі-сть товару, Дата з якої очікується поставка, Необхідний граничний термін постачання, Дата початку, Дата завершення",
        info: false
    },
    {
        checked: false,
        title: "itemPriceVariation",
        description_en: "an increase in the unit's price of goods to 10% in proportion to the increase in the price of such goods on the market in the event of fluctuations in the price of such goods on the market, provided that such change does not increase the amount specified in the procurement contract, - not more than once every 90 days from the moment of signing the procurement contract. Restrictions on the terms of price change per unit of goods do not apply in cases of changes in the terms of the contract for the purchase of gasoline and diesel fuel, gas and electricity",
        title_en: "Change in the unit's price",
        description_uk: "збільшення ціни за одиницю товару до 10 відсотків пропорційно збільшенню ціни такого товару на ринку у разі коливання ціни такого товару на ринку за умови, що така зміна не призведе до збільшення суми, визначеної в договорі про закупівлю, - не частіше ніж один раз на 90 днів з моменту підписання договору про закупівлю. Обмеження щодо строків зміни ціни за одиницю товару не застосовується у випадках зміни умов договору про закупівлю бензину та дизельного пального, газу та електричної енергії (Закон)",
        title_uk: "Зміна ціни за одиницю товару",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ, Інф про номенкл, кі-сть товару",
        info: false
    },
    {
        checked: false,
        title: "itemPriceChange",
        description_en: "approval of a change in the price per product unit in the purchase contract in the event of a fluctuation in the price of such product on the market, which has occurred since the conclusion of the purchase contract or the last amendment to the purchase contract in terms of a change in the price per product unit. The change in the price per unit of the product is carried out in proportion to the fluctuation of the price of such a product on the market (the percentage increase in the price per unit of the product cannot exceed the percentage of the fluctuation (increase) in the price of such a product on the market) provided that such fluctuation is documented and should not lead to an increase in the amount specified in the purchase agreement at the time of its conclusion",
        title_en: "Change in the unit's price",
        description_uk: "погодження зміни ціни за одиницю товару в договорі про закупівлю у разі коливання ціни такого товару на ринку, що відбулося з моменту укладання договору про закупівлю або останнього внесення змін до договору про закупівлю в частині зміни ціни за одиницю товару. Зміна ціни за одиницю товару здійснюється пропорційно коливанню ціни такого товару на ринку (відсоток збільшення ціни за одиницю товару не може перевищувати відсоток коливання (збільшення) ціни такого товару на ринку) за умови документального підтвердження такого коливання та не повинна призвести до збільшення суми, визначеної в договорі про закупівлю на момент його укладення (Постанова)",
        title_uk: "Зміна ціни за одиницю товару",
        archive: false,
        changes: "Ціна договору, Ціна договору без ПДВ, Ціна за одиницю",
        info: false
    },
    {
        checked: false,
        title: "priceReduction",
        description_en: "approval of price changes in the procurement contract in the direction of reduction (without changing the quantity (volume) and quality of goods, works and services), including in case of fluctuations in the price of goods on the market",
        title_en: "Coordinated price reduction",
        description_uk: "погодження зміни ціни в договорі про закупівлю в бік зменшення (без зміни кількості (обсягу) та якості товарів, робіт і послуг), у тому числі у разі коливання ціни товару на ринку (Закон)",
        title_uk: "Узгоджене зменшення ціни",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ",
        info: false
    },
    {
        checked: false,
        title: "priceReductionWithoutQuantity",
        description_en: "approval of a downward price change in the purchase agreement (without changing the quantity (volume) and quality of goods, works and services)",
        title_en: "Coordinated price reduction",
        description_uk: "погодження зміни ціни в договорі про закупівлю в бік зменшення (без зміни кількості (обсягу) та якості товарів, робіт і послуг) (Постанова)",
        title_uk: "Узгоджене зменшення ціни",
        archive: false,
        changes: "Ціна договору, Ціна договору без ПДВ, Ціна за одиницю",
        info: false
    },
    {
        checked: false,
        title: "qualityImprovement",
        description_en: "improvement of the item's quality, provided that such improvement will not increase the sum of money specified in the procurement contract",
        title_en: "Improvement of the procurement item's quality",
        description_uk: "покращення якості предмета закупівлі, за умови що таке покращення не призведе до збільшення суми, визначеної в договорі про закупівлю",
        title_uk: "Покращення якості предмета закупівлі",
        archive: false,
        changes: "Назва контракту",
        info: false
    },
    {
        checked: false,
        title: "taxRate",
        description_en: "price changes in the procurement contract due to changes in tax rates and fees and/or changes in the conditions for granting tax benefits - in proportion to changes of such rates and/or tax benefits",
        title_en: "Price changes due to changes in tax rates and fees",
        description_uk: "зміна ціни в договорі про закупівлю у зв’язку зі зміною ставок податків і зборів та/або зміною умов щодо надання пільг з оподаткування - пропорційно до змін таких ставок та/або пільг з оподаткування (Закон)",
        title_uk: "Зміна ціни у зв’язку із зміною ставок податків і зборів",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ",
        info: false
    },
    {
        checked: false,
        title: "taxationSystem",
        description_en: "price changes in the purchase contract in connection with changes in tax rates and fees, changes in the taxation system and/or changes in the conditions for granting tax benefits - in proportion to changes in such rates and/or tax benefits",
        title_en: "Price changes due to changes in tax rates and fees",
        description_uk: "зміни ціни в договорі про закупівлю у зв’язку зі зміною ставок податків і зборів, зміною системи оподаткування та/або зміною умов щодо надання пільг з оподаткування - пропорційно до зміни таких ставок та/або пільг з оподаткування (Постанова)",
        title_uk: "Зміна ціни у зв’язку із зміною ставок податків і зборів",
        archive: false,
        changes: "Ціна договору, Ціна договору без ПДВ, Ціна за одиницю, Ознака ПДВ",
        info: false
    },
    {
        checked: false,
        title: "thirdParty",
        description_en: "changes of the established under the law by the State Statistics Service consumer price index, changes in foreign currency rate, changes in stock prices or Platts indices, ARGUS regulated prices (rates) and standards that are used in the procurement contract if the price change order is specified in the procurement contract",
        title_en: "Change of the third-party indicators (rate, indices...)",
        description_uk: "зміна встановленого згідно із законодавством органами державної статистики індексу споживчих цін, зміни курсу іноземної валюти, зміни біржових котирувань або показників Platts, ARGUS регульованих цін (тарифів) і нормативів, що застосовуються в договорі про закупівлю, у разі встановлення в договорі про закупівлю порядку зміни ціни (Закон)",
        title_uk: "Зміна сторонніх показників (курсу, тарифів...)",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ",
        info: false
    },
    {
        checked: false,
        title: "externalIndicators",
        description_en: "changes in the consumer price index established in accordance with the law by the state statistics authorities, changes in the foreign currency exchange rate, changes in stock exchange quotations or indicators of Platts, ARGUS, regulated prices (tariffs), standards, weighted average prices for electricity on the \"day-ahead\" market, which are applied in the contract on purchase, in the event that the procedure for changing the price is established in the purchase contract",
        title_en: "Change of the third-party indicators (rate, indices...)",
        description_uk: "зміни встановленого згідно із законодавством органами державної статистики індексу споживчих цін, зміни курсу іноземної валюти, зміни біржових котирувань або показників Platts, ARGUS, регульованих цін (тарифів), нормативів, середньозважених цін на електроенергію на ринку \"на добу наперед\", що застосовуються в договорі про закупівлю, у разі встановлення в договорі про закупівлю порядку зміни ціни (Постанова)",
        title_uk: "Зміна сторонніх показників (курсу, тарифів...)",
        archive: false,
        changes: "Ціна договору, Ціна договору без ПДВ, Ціна за одиницю",
        info: false
    },
    {
        checked: false,
        title: "volumeCuts",
        description_en: "reduction of the procurement's volume, particularly taking into account the actual expenditures of the procuring entity",
        title_en: "Reduction of the procurement's volume",
        description_uk: "зменшення обсягів закупівлі, зокрема з урахуванням фактичного обсягу видатків замовника",
        title_uk: "Зменшення обсягів закупівлі",
        archive: false,
        changes: "Назва контракту, Ціна договору, Ціна договору без ПДВ, Ціна за одиницю, Конкретна назва предмета закупівлі, Інф про номенкл, кі-сть товару, Місце поставки товарів",
        info: false
    }
]);
